import { render, staticRenderFns } from "./edit-contact-list-modal.vue?vue&type=template&id=3115dc57&scoped=true"
import script from "./edit-contact-list-modal.vue?vue&type=script&lang=js"
export * from "./edit-contact-list-modal.vue?vue&type=script&lang=js"
import style0 from "./edit-contact-list-modal.vue?vue&type=style&index=0&id=3115dc57&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3115dc57",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2108204121/src/web/components/loading-spinner.vue').default,TableActions: require('/codebuild/output/src2108204121/src/web/components/table-actions.vue').default,SaveButton: require('/codebuild/output/src2108204121/src/web/components/save-button.vue').default,ConfirmModal: require('/codebuild/output/src2108204121/src/web/components/confirm-modal.vue').default})
