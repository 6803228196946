

import SaveButton from "../save-button";

export default {
    name: "CompanyFeedbackModal",
    components: {
        SaveButton,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "companyFeedbackModal",
        },
        event: {
            type: Object,
            required: true,
        },
        feedbackCell: {
            type: Object,
            required: false,
            default() {
                return {
                    companyName: null,
                    feedback: null,
                    aiUsedNotesIds: [],
                    eventHasCompanyId: null,
                }
            },
        },
        isNoteSummary: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            cellWorkingCopy: {
                feedback: null,
            },
            saving: false,
            aiGenerating: false,
            newNotes: 0,
        }
    },
    methods: {
        formSubmitHandler() {
            this.saving = true;
            if(this.isNoteSummary) {
                this.$axios.patch('/api/events/' + this.event.id, {
                    noteSummary: this.cellWorkingCopy.feedback,
                    aiUsedNotesIds: this.cellWorkingCopy.aiUsedNotesIds,
                }, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                }).then((response) => {
                    this.saving = false;
                    this.$emit('event-updated', response.data)
                });
            } else {
                this.$axios.patch('/api/event_has_companies/' + this.cellWorkingCopy.eventHasCompanyId, {
                    feedback: this.cellWorkingCopy.feedback,
                    aiUsedNotesIds: this.cellWorkingCopy.aiUsedNotesIds,
                }, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                }).then((response) => {
                    this.saving = false;
                    this.$emit('feedback-updated', response.data);
                });
            }
        },
        resetWorkingCopy() {
            if (this.isNoteSummary) {
                this.cellWorkingCopy = {
                    feedback: this.event.noteSummary,
                    aiUsedNotesIds: this.event.aiUsedNotesIds
                };

            } else {
                this.cellWorkingCopy = structuredClone(this.feedbackCell);
                console.log(this.cellWorkingCopy);
            }
            this.loadNewNotes();
        },
        loadNewNotes() {
            if(this.isNoteSummary) {
                this.$axios.get('/api/events/has_new_event_notes/' + this.event.id).then((response) => {
                    this.newNotes = response.data;
                });
            } else {
                this.$axios.get('/api/events/has_new_feedback_notes/' + this.cellWorkingCopy.eventHasCompanyId).then((response) => {
                    this.newNotes = response.data;
                });
            }
        },
        generate(modeGen) {
            if(!this.isUserWithRoleCorporateAccess) return;

            this.aiGenerating = true;
            if(this.isNoteSummary) {
                this.$axios.post('/api/events/note_summary/' + this.event.id + '?mode=' + (modeGen ? 'generate' : 'merge')
                ).then((response) => {
                    this.cellWorkingCopy.feedback = response.data.response;
                    this.cellWorkingCopy.aiUsedNotesIds = response.data.notes;
                }).finally(() => {
                    this.newNotes = 0;
                    this.aiGenerating = false;
                });
            } else {
                const params = {
                    companyId: this.feedbackCell.companyId,
                    eventId: this.event.id,
                    mode: modeGen ? "generate" : "merge",
                };
                this.$axios.post('/api/events/feedback_summary?' + this.buildQueryString(params), {
                    feedback: modeGen ? "" : this.cellWorkingCopy.feedback,
                }).then((response) => {
                    this.cellWorkingCopy.feedback = response.data.response;
                    this.cellWorkingCopy.aiUsedNotesIds = response.data.notes;
                }).finally(() => {
                    this.newNotes = 0;
                    this.aiGenerating = false;
                });
            }
        },
    }
}
