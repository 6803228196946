


export default {
    name: "GroupsCard",
    components: {},
    props: {
        companyGroups: {
            type: Array,
            default: () => []
        },
        contactGroups: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            baseurl: null,
        }
    },

    mounted() {
        this.baseurl = window.location.origin;
        console.log(this.contactGroups)
    },
    methods: {}
}


