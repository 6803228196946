

import SaveButton from "../save-button.vue";
import htmlDiff from "../../plugins/htmldiff"

export default {
    name: "EmailRevisionModal",
    components: {SaveButton},
    props: {
        email: {
            required: true
        },
    },
    data() {
        return {
            generating: false,
            adjustment: null,
            lockSubject: false,
            emailWorkingCopy: {
                subject: null,
                body: null,
                isGroupEmail: true,
            },
            showImageWarning: false,
            showDiff: false,
            mailBodyModel: null,
        }
    },
    computed: {
        placeholders() {
            // taken from edit.vue
            if (!this.userInfo.email_placeholders) {
                return [];
            }

            if (this.emailWorkingCopy.isGroupEmail) {
                return this.userInfo.email_placeholders.filter(
                    x =>
                        [
                            this.userInfo.email_placeholder_mapping.show_always,
                            this.userInfo.email_placeholder_mapping.show_group
                        ].includes(x.show)
                );
            } else {
                return this.userInfo.email_placeholders.filter(
                    x => [
                        this.userInfo.email_placeholder_mapping.show_always,
                        this.userInfo.email_placeholder_mapping.show_regular,
                        this.emailWorkingCopy.allRecipientsHaveSessions1x1Urls ? this.userInfo.email_placeholder_mapping.show_1x1_event : "__NOT_EXISTING_SHOW__ATTRIBUTE__",
                        this.emailWorkingCopy.allRecipientsHaveSessionsAgendaUrls ? this.userInfo.email_placeholder_mapping.show_agenda_event : "__NOT_EXISTING_SHOW__ATTRIBUTE__"
                    ].includes(x.show)
                );
            }
        },
    },
    watch: {
        emailWorkingCopy() {
            this.mailBodyModel = this.emailWorkingCopy.body;
        }
    },
    methods: {
        resetWorkingCopy() {
            if (!this.email) return;
            this.emailWorkingCopy = structuredClone(this.email);
            this.generating = this.lockSubject = this.showImageWarning = false;
            this.adjustment = null;
        },
        generate() {
            if (this.emailWorkingCopy.body.includes("<img")) {
                this.showImageWarning = true;
                return;
            }
            this.generating = true;
            this.showImageWarning = false;
            this.toggleDiff(true);
            this.$axios.post("/api/emails/improve_email_ai", {
                subject: this.emailWorkingCopy.subject,
                body: this.emailWorkingCopy.body,
                adjustment: this.adjustment,
                lockSubject: this.lockSubject,
            }).then(response => {
                if (!this.generating) return;
                this.emailWorkingCopy.subject = response.data.subject;
                this.emailWorkingCopy.body = this.mailBodyModel = response.data.body;
                this.adjustment = null;
                this.toggleDiff(true);
            }).finally(() => {
                this.generating = false;
            });
        },
        accept() {
            this.toggleDiff(true);
            this.$emit("email-updated", this.emailWorkingCopy.subject, this.emailWorkingCopy.body);
            this.$emit("hide-modal");
        },
        toggleDiff(force) {
            const wasShowDiff = this.showDiff;
            this.showDiff = (force === null) ? !this.showDiff : force;

            if (this.showDiff) {
                if(!wasShowDiff) this.emailWorkingCopy.body = this.mailBodyModel;
                this.mailBodyModel = htmlDiff(this.email.body, this.emailWorkingCopy.body)
                    .replaceAll("<ins>", "<ins style='background: lightgreen; text-decoration: none;'>")
                    .replaceAll("<del>", "<del style='background: pink;'>");
            } else if (wasShowDiff) {
                this.mailBodyModel = this.emailWorkingCopy.body;
            }
        }
    }
}
