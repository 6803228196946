
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import LoadingSpinner from "../loading-spinner";
import ConfirmModal from "../confirm-modal";
import modals from "../../mixins/modals";
import HintText from "../hint-text";
import UploadDropZone from "../upload-drop-zone";
import ContactName from "../contacts/contact-name";
import SaveButton from "../save-button.vue";
import DraftModal from "./draft-modal";
import ScheduleModal from "./schedule-modal";
import SaveTemplateModal from "./save-template-modal";
import SendModal from "./send-modal";
import SelectTestRecipientUserModal from "./select-test-recipient-user-modal.vue";
import EmailRevisionModal from "./email-revision-modal.vue";
import optionButtonTooltip from "~/mixins/option-button-tooltip";


export default {
    name: "EditEmail",
    components: {
        SaveButton,
        EmailRevisionModal,
        SelectTestRecipientUserModal,
        SendModal,
        ContactName,
        UploadDropZone,
        HintText,
        SaveTemplateModal,
        ConfirmModal,
        ScheduleModal,
        DraftModal,
        LoadingSpinner,
    },
    mixins: [
        modals,
        optionButtonTooltip,
    ],
    props: {
        id: {
            type: Number,
            default: null,
        },
        redraft: {
            type: Boolean,
            default: false,
        },
        bulkOperation: {
            type: Boolean,
            default: false
        },
        group: {
            type: Number,
            default: null
        },
        contactIds: {
            type: Array,
            required: false,
            default: null,
        },
        emailAddresses: {
            type: Array,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            testSent: false,
            test: true,
            highlightDropArea: false,
            sender: null,
            attachments: [],
            email: null,
            recipients: {
                to: [],
                cc: [],
                bcc: [],
                groups: [],
                excluded: [],
            },
            sending: false,
            testSending: false,
            emailTemplate: null,
            emailRecipientTemplate: null,
            emailAttachmentTemplate: null,
            contactTemplate: null,
            toValidation: false,
            ccValidation: false,
            bccValidation: false,
            // autoSaveTimeout: null,
            mostUsedContactLists: [],
            abcListEmailCcContacts: [],
            scheduleModal: null,
            selectTestRecipientUserModal: null,
            newTemplate: false,
            // autosaveInterval: 15 * 1000,
            preventEditing: false,
            preventFiringBodyChanged: false,
            confirmDeleteText: null,
            // autoSaved: false,
            saving: false,
            hasErrors: false,
            usersContactLists: [],
            editorKey: moment().valueOf(),
            availableDomains: [],
            selectedDomain: null,
            emailTransport: 'm365',
            sizes: [],
            saveDropdownUpdated: moment().valueOf(),
            sendDropdownUpdated: moment().valueOf(),
            sendTestDropdownUpdated: moment().valueOf(),
            simulatingSubmit: false,
            callbackAfterValidation: null,
            callbackBeforeNextSubmission: null,
            callbackOnSubmission: null,
            loading: false,
            domainsLoading: false,
            savingAsDraft: false,
            sendingTestEmail: false,
            savingAsTemplate: false,
            collapseShown: false,
            bodyKeyboardInput: false,
            revisionModal: null,
        }
    },
    computed: {
        ...mapGetters({
            bulkContactFiltersHash: "app/bulkContactFiltersHash",
            storedEmail: "app/storedEmail",
            currentEmail: "app/currentEmail",
        }),
        placeholders() {
            if (!this.userInfo.email_placeholders) {
                return [];
            }

            if (this.email.isGroupEmail) {
                return this.userInfo.email_placeholders.filter(
                    x =>
                        [
                            this.userInfo.email_placeholder_mapping.show_always,
                            this.userInfo.email_placeholder_mapping.show_group
                        ].includes(x.show)
                );
            } else {
                return this.userInfo.email_placeholders.filter(
                    x => [
                        this.userInfo.email_placeholder_mapping.show_always,
                        this.userInfo.email_placeholder_mapping.show_regular,
                        this.email.allRecipientsHaveSessions1x1Urls ? this.userInfo.email_placeholder_mapping.show_1x1_event : "__NOT_EXISTING_SHOW__ATTRIBUTE__",
                        this.email.allRecipientsHaveSessionsAgendaUrls ? this.userInfo.email_placeholder_mapping.show_agenda_event : "__NOT_EXISTING_SHOW__ATTRIBUTE__"
                    ].includes(x.show)
                );
            }
        },

        // emailComplete() {
        //    return this.email.isTemplate ||
        //        ( this.email.subject &&
        //            (this.recipients.groups.length || this.recipients.to.length)
        //        );
        // },
        totalRecipients() {
            let number = this.recipients.to.length;
            if (this.recipients.groups.length > 0) {
                number += this.recipients.groups.map(x => x.numberOfContacts).reduce((sum, x) => sum + x);
            }
            return number;
        },
        emailTransportOptions() {
            const options = {};


            if (this.availableDomains.length > 0) {
                options.smtp = 'Default';
            }

            if (this.userInfo.m365_connected) {
                options.m365 = 'Microsoft 365';
            }

            return options;
        },
        isSendingEmailDisabled() {
            if (this.saving) {
                return true;
            }
            if (this.userInfo.disable_email_sending) {
                return true;
            }
            if (this.userInfo.disable_sending_as && this.sender.id !== this.userInfo.contact_id) {
                return true;
            }
            if (this.email && this.email.isTemplate) {
                return true;
            }
            if (
                // !this.email.scheduledAt &&
                ((!this.testSent && this.email.testRequired) || !!this.email.sentAt)) {
                return true;
            }

            return false;
        },
        sendEmailTitle() {
            if (this.saving) {
                return 'Saving...';
            }
            if (this.userInfo.disable_email_sending) {
                return 'You cannot send emails.';
            }
            if (
                this.userInfo.disable_sending_as &&
                this.sender &&
                this.sender.id !== this.userInfo.contact_id
            ) {
                return 'You cannot send emails for other users.';
            }
            if (this.email && this.email.isTemplate) {
                return "This is an email template. To send an email with this template, " +
                    "first create a draft (e.g., with 'Save as > Email draft'), then send it.";
            }
            // if (!this.email.scheduledAt) {
            if (!this.testSent && this.email.testRequired) {
                return 'Send a test email to proceed with sending.';
            }
            if (this.email.sentAt) {
                return 'The email has already been sent.';
            }
            // }

            return 'Send the email - now or later';
        },
        recipientValidationRule() {
            if (!this.recipientsRequired) {
                // No Recipients needed fot test emails
                return this.toValidation ? 'email' : '';
            } else {
                // If toValidation is true we need to validate that input has email values
                // Otherwise, when there is no group selected, we need to set this as required
                return this.toValidation ? 'email' : (this.recipients.groups.length <= 0 ? 'required' : '');
            }
        },
        recipientGroupValidationRule() {
            if (!this.recipientsRequired) {
                // No Recipients needed fot test emails
                return '';
            } else {
                // Required if no recipients selected
                return this.recipients.to.length <= 0 ? 'required' : '';
            }
        },
        recipientsRequired() {
            return !this.email.isTest && !this.savingAsTemplate && !this.savingAsDraft && !this.sendingTestEmail;
        },
        showDomainSelection() {
            return this.emailTransport === "smtp";
        },
        isSendingDisabled() {
            return this.userInfo.disable_email_sending || // user is not allowed to send emails
                this.saving || // emails is currently being saved
                (!this.email.scheduledAt && ((!this.testSent && this.email.testRequired) || !!this.email.sentAt)) || // check if the email is either unscheduled and a test hasn't been sent, or it's already been sent
                (this.userInfo.disable_sending_as && this.sender.id !== this.userInfo.contact_id) // user is not allowed to send in a different users name but tries to
        },
    },
    watch: {

        emailTransportOptions(val) {
            if (val.smtp && !this.userInfo.m365_connected) {
                this.emailTransport = "smtp";
            }
        },
        email: {
            handler(val) {
                if (!this.preventEditing) {
                    this.testSent = false;
                }
                if (val && val.id) {
                    this.setCurrentEmail(JSON.parse(JSON.stringify(val)));
                }
            },
            deep: true
        },
        recipients: {
            handler() {
                this.testSent = false;
            },
            deep: true
        },
        "recipients.to": {
            handler() {
                this.toValidation = false;
            }
        },
        "recipients.cc": {
            handler() {
                this.ccValidation = false;
            }
        },
        "recipients.bcc": {
            handler() {
                this.bccValidation = false;
            }
        },
        "email.body": {
            handler(val, old) {
                // if (!val && !old) {
                //     this.preventFiringBodyChanged = false;
                // }

                /* FIXME Disabled Autosave 29.06.2022
        clearTimeout(this.autoSaveTimeout);

        // valid subject and recipients are set and body has value and is not initial. The email has not been not sent yet
        if (
            this.emailComplete &&
            val &&
            typeof old !== "undefined" &&
            !this.email.sentAt &&
            !this.preventEditing

        ) {

            this.autoSaveTimeout = setTimeout(() => {
                alert("Autosaving now!");
                this.email.isTest = false;
                this.email.isDraft = !this.email.isTemplate;
                this.$formulate.submit("email-form")
                this.autoSaved = true;

            }, this.autosaveInterval)

        } */
            }
        },
        "email.isGroupEmail": {
            handler() {
                this.editorKey = moment().valueOf();
            }
        },
        "email.isAbcListEmail": {
            handler(val, old) {
                if (val) {
                    this.$set(this.email, "isPlain", true);
                    this.$set(this.email, "isGroupEmail", true);
                    this.$set(this.email, "isPrivate", true);

                    if (!this.recipients.cc.map(x => x.id).some(c => this.abcListEmailCcContacts.map(x => x.id).includes(c))) {
                        this.$set(this.recipients, "cc", [...this.recipients.cc, ...JSON.parse(JSON.stringify(this.abcListEmailCcContacts))])
                    }
                } else if ((old === true) && (val === false)) { // make this check type safe so initialization (old = undefined) is not considered as change
                    // ABC List email has just been turned off
                    this.$set(this.email, "isGroupEmail", false);
                    this.recipients.cc = this.recipients.cc.filter(c => !this.abcListEmailCcContacts.map(x => x.id).includes(c.id))
                } else {
                    // Keep everything as it is.
                }
            }
        },
        sender: {
            handler(val) {
                if (val && val.id > 0) {
                    const senderContactId = val.id;
                    this.loadAvailableDomains(senderContactId);
                } else {
                    this.availableDomains = [];
                    this.selectedDomain = null;
                }

            },
            immediate: true,
        },
    },
    created() {
        this.loading = true;
        const promises = [
            this.loadMostUsedContactLists(),
            this.loadAbcListEmailCcContacts(),
            this.loadEmptyEmail(),
            this.loadEmptyEmailRecipient(),
            this.loadEmptyEmailAttachment(),
            this.loadUsersContactLists(),
        ];

        if (this.contactIds && this.contactIds.length > 0) {
            for (const contactId of this.contactIds) {
                promises.push(this.loadRecipientContact(contactId));
            }
        } else if (this.group) {
            promises.push(this.loadRecipientGroup())
        }

        if (this.bulkOperation) {
            promises.push(this.loadBulkRecipientContacts())
        }

        if (this.emailAddresses && this.emailAddresses.length > 0) {
            for (const emailAddress of this.emailAddresses) {
                this.recipients.to.push({
                    nameWithCompany: emailAddress
                });
            }
        }

        if (this.id) {
            promises.push(this.loadEmail(this.id, this.redraft))
            Promise.all(promises).then(() => {
                // if redraft -> set sender to current user
                if (this.redraft) {
                    this.sender = {
                        id: this.userInfo.contact_id,
                        "@id": `/api/contacts/${this.userInfo.contact_id}`,
                        nameWithCompany: this.userInfo.name
                    };
                    this.$set(this.email, "authorUser", `/api/users/${this.userInfo.id}`)
                    this.loadAvailableDomains(this.userInfo.contact_id);
                }

                if (this.email.isTest) {
                    this.testSent = true;
                    this.initializeOptionButtonTooltips()

                    this.preventEditing = true;

                    this.$set(this.email, "isTest", false)

                    this.$nextTick(() => {
                        this.preventEditing = false;
                    })

                }

                this.loading = false;

            });
        } else {
            Promise.all(promises).then(() => {
                this.initEmail();
                this.initializeOptionButtonTooltips()
                this.loading = false;
            });
        }

    },
    methods: {
        ...mapActions({
            setStoredEmail: "app/setStoredEmail",
            setCurrentEmail: "app/setCurrentEmail",
        }),

        loadAvailableDomains(senderContactId) {
            this.domainsLoading = true;
            this.$axios.get('/api/email/domains/' + senderContactId)
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.availableDomains = response.data.map((domain) => {
                            return {
                                value: domain,
                                label: domain
                            }
                        });
                    } else {
                        if (!this.userInfo.m365_connected) {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "danger",
                                title: "Invalid config",
                                message: "No email delivery method is available, please log in with Microsoft 365.",
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            })
                        }
                        this.availableDomains = [];
                        this.selectedDomain = null;
                    }
                })
                .catch(() => {
                    this.availableDomains = [];
                    this.selectedDomain = null;
                })
                .finally(() => {
                    this.domainsLoading = false;
                });
        },

        saveAsTemplate(isNew = true) {
            if (this.callbackBeforeNextSubmission) {
                this.callbackBeforeNextSubmission();
            }

            this.savingAsTemplate = true;
            this.callbackBeforeNextSubmission = () => {
                this.savingAsTemplate = false;
                this.newTemplate = false;
            }

            this.callbackAfterValidation = () => {
                if (!this.hasErrors) {
                    this.newTemplate = isNew;
                    this.openModal(this.$refs.saveTemplateModal)
                } else {
                    this.saveDropdownUpdated = moment().valueOf();
                }
            }

            this.$nextTick(() => {
                this.simulateSubmit();
            })
        },

        saveAsDraft() {
            if (this.callbackBeforeNextSubmission) {
                this.callbackBeforeNextSubmission();
            }

            this.savingAsDraft = true;
            this.callbackBeforeNextSubmission = () => {
                this.savingAsDraft = false;
            }

            this.callbackAfterValidation = () => {
                if (!this.hasErrors) {
                    this.openModal(this.$refs.saveDraftModal);
                } else {
                    this.saveDropdownUpdated = moment().valueOf();
                }
            }

            this.$nextTick(() => {
                this.simulateSubmit();
            })
        },

        doSaveAsTemplate(args) {
            if (this.newTemplate) {
                this.$set(this.email, "id", null)
                args.saveAs = true;
            }
            this.$set(this.email, "isTest", false)
            this.$set(this.email, "isDraft", false)
            this.$set(this.email, "isTemplate", true)

            this.$set(this.email, "scheduledAt", null)
            this.$set(this.email, "scheduledAtTimezone", null)
            this.$set(this.email, "isPrivate", args.private)


            this.doSubmitForm(args)
        },


        openScheduleModal() {
            if (this.callbackBeforeNextSubmission) {
                this.callbackBeforeNextSubmission();
            }

            this.simulateSubmit();

            this.callbackAfterValidation = () => {
                if (!this.hasErrors) {
                    this.scheduleModal = this.openModal(this.$refs.scheduleModal);
                } else {
                    this.sendDropdownUpdated = moment().valueOf();
                }
            }
        },

        doSaveAsDraft(args) {

            let promise = Promise.resolve();

            // TODO this should never happen
            if (this.email.id && !this.email.isDraft && !this.email.isTemplate) {
                console.log("can this happen???")
                promise = this.loadEmail(null, true)
            }

            const submitArgs = {};


            // save as new entry, if it is a template (or another thing) and not a draft
            if (!this.email.isDraft) {
                this.$set(this.email, "id", null)
                submitArgs.saveAs = true;
            }

            promise.then(() => {

                this.$set(this.email, "draftUser", args.user)

                this.$set(this.email, "isDraft", true)
                this.$set(this.email, "isTemplate", false)
                this.$set(this.email, "isTest", false)
                this.$set(this.email, "scheduledAt", null)
                this.$set(this.email, "scheduledAtTimezone", null)

                this.$set(this.email, "isPrivate", args.private)

                this.doSubmitForm(submitArgs);
            })
        },

        scheduleEmail(args) {
            // When submitting as scheduled email, it becomes a campaign (isDraft = false)
            this.scheduleModal.hide();

            this.$set(this.email, "isDraft", false)
            this.$set(this.email, "isTemplate", false)
            this.$set(this.email, "isTest", false)

            this.doSubmitForm(args);
        },

        doSendEmail() {
            // clearTimeout(this.autoSaveTimeout);

            const stored = {
                isTest: this.email.isTest,
                isDraft: this.email.isDraft,
            };

            if (this.callbackBeforeNextSubmission) {
                this.callbackBeforeNextSubmission();
            }

            this.preventEditing = true;

            this.$set(this.email, "isDraft", false)
            this.$set(this.email, "isTest", false)


            this.callbackBeforeNextSubmission = () => {
                this.preventEditing = true;
                this.$set(this.email, "isTest", stored.isTest)
                this.$set(this.email, "isDraft", stored.isDraft)

                this.$nextTick(() => {
                    this.preventEditing = false;
                });
            }

            this.callbackAfterValidation = () => {
                if (!this.hasErrors) {
                    // This then does the actual submit after asking for name etc.
                    if (!this.email.testRequired) {
                        this.doSubmitForm();
                    } else {
                        this.openModal(this.$refs.confirmSendEmailModal);
                    }
                }
            }

            this.$nextTick(() => {
                this.preventEditing = false;
                this.simulateSubmit();
            })
        },
        selectTestRecipientUser() {
            this.selectTestRecipientUserModal = this.openModal(this.$refs.selectTestRecipientUserModal);
        },
        doSendTestEmailToYourself() {

            this.preventEditing = true;
            this.$set(this.email, "testRecipientUser", `/api/users/${this.userInfo.id}`)
            this.doSendTestEmail();
        },
        doSendTestEmailToAnotherUser(userId) {
            this.closeModal(this.selectTestRecipientUserModal);

            this.preventEditing = true;
            this.$set(this.email, "testRecipientUser", `/api/users/${userId}`)
            this.doSendTestEmail();
        },
        doSendTestEmail() {
            // clearTimeout(this.autoSaveTimeout);

            const stored = {
                isTest: this.email.isTest,
                isDraft: this.email.isDraft,
            };


            this.preventEditing = true;

            if (this.callbackBeforeNextSubmission) {
                this.callbackBeforeNextSubmission();
            }

            this.$set(this.email, "isTest", true)
            this.$set(this.email, "isDraft", !this.email.isTemplate)

            this.sendingTestEmail = true;
            this.callbackOnSubmission = () => {
                this.callbackBeforeNextSubmission = () => {
                    this.sendingTestEmail = false;
                }
            }

            this.callbackBeforeNextSubmission = () => {

                this.preventEditing = true;
                this.$set(this.email, "isTest", stored.isTest)
                this.$set(this.email, "isDraft", stored.isDraft)
                this.$nextTick(() => {
                    this.preventEditing = false;
                })
            }

            // Do a submit.
            this.$nextTick(() => {
                this.preventEditing = false;
                this.triggerSubmit();
            })

        },

        loadAbcListEmailCcContacts() {
            return this.$axios.get("/api/contacts/abc_list_email_cc_contacts").then(response => {
                this.abcListEmailCcContacts = response.data["hydra:member"];
            });
        },
        loadUsersContactLists() {
            return this.$axios.get(`/api/contact_lists?creatingUser=${this.userInfo.id}&pagination=1&order[id]=desc&groups[]=email:read`).then(response => {
                this.usersContactLists = response.data["hydra:member"];
            });
        },
        loadMostUsedContactLists() {
            return this.$axios.get("/api/contact_lists/most_used?groups[]=email:read").then(response => {
                this.mostUsedContactLists = response.data["hydra:member"];
            });
        },
        loadRecipientContact(contactId) {
            return this.$axios.get(`/api/contacts/${contactId}`).then(response => {
                this.recipients.to.push(response.data);
            });
        },
        loadBulkRecipientContacts() {
            return this.$axios.get("/api/contacts/bulk_contacts?groups[]=contact:basics", {
                params: {
                    hash: this.bulkContactFiltersHash
                }
            }).then(response => {
                response.data["hydra:member"].forEach((c) => {
                    if (c.isExcludedFromEmails) {
                        this.recipients.excluded.push(c);
                    } else {
                        this.recipients.to.push(c);
                    }
                })
            });

        },
        loadRecipientGroup() {
            return this.$axios.get(`/api/contact_lists/${this.group}?groups[]=email:read`).then(response => {
                this.recipients.groups.push(response.data);
            });
        },
        loadEmail(id = null, redraft = false) {

            return new Promise((resolve) => {

                let promise;
                if (id && this.storedEmail) {
                    const email = JSON.parse(JSON.stringify(this.storedEmail))
                    this.setStoredEmail(null);
                    promise = Promise.resolve({data: email});
                } else if (id) {
                    promise = this.$axios.get(`/api/emails/${id}`)
                } else {
                    const email = JSON.parse(JSON.stringify(this.email))
                    promise = Promise.resolve({data: email});
                }

                promise.then((response) => {
                    this.preventEditing = true;
                    this.preventFiringBodyChanged = true;
                    this.email = response.data;
                    this.getFilesizes(response.data.id)
                    this.$nextTick(() => {
                        this.preventEditing = false;
                    })

                    const to = [];
                    const cc = [];
                    const bcc = [];
                    const groups = [];
                    const excluded = [];

                    for (const emailRecipient of response.data.emailRecipients) {
                        if (emailRecipient.isTo && emailRecipient.contactList) {
                            if (!groups.map(g => g.id).includes(emailRecipient.contactList.id)) {
                                groups.push(emailRecipient.contactList)
                            }
                        } else if (emailRecipient.contact.isExcludedFromEmails) {
                            excluded.push(emailRecipient.contact)
                        } else {
                            if (emailRecipient.isTo) {
                                to.push(emailRecipient.contact);
                            }
                            if (emailRecipient.isCc) {
                                cc.push(emailRecipient.contact);
                            }
                            if (emailRecipient.isBcc) {
                                bcc.push(emailRecipient.contact);
                            }
                        }
                    }

                    this.recipients = {
                        to,
                        cc,
                        bcc,
                        groups,
                        excluded,
                    };

                    this.sender = response.data.sendingContact;
                    this.selectedDomain = response.data.senderDomain;
                    this.emailTransport = response.data.isM365Sender ? 'm365' : 'smtp';
                    if (redraft) {

                        this.$delete(this.email, "id");
                        this.$delete(this.email, "@id");

                        this.$set(this.email, "isDraft", true)
                        this.$set(this.email, "isTemplate", false)
                        this.$set(this.email, "sentAt", null)
                        this.$set(this.email, "scheduledAt", null)
                        this.$set(this.email, "scheduledAtTimezone", null)
                        this.$set(this.email, "authorUser", `/api/users/${this.userInfo.id}`)
                        this.$set(this.email, "eventToRegisterAt", response.data.eventToRegisterAt)


                        this.$set(this.email, "emailRecipients", [])
                        this.$set(this.email, "toContactEmails", [])
                        this.$set(this.email, "toContactIds", [])
                        this.$set(this.email, "ccContactEmails", [])
                        this.$set(this.email, "ccContactIds", [])
                        this.$set(this.email, "bccContactEmails", [])
                        this.$set(this.email, "bccContactIds", [])

                        const attachmentPromises = [];
                        this.email.emailAttachments.forEach((a) => {
                            attachmentPromises.push(new Promise(resolve => {
                                fetch(a.fileUrl)
                                    .then((response) => {
                                        response.blob().then(blob => {
                                            // const data = Buffer.from(response.data, 'binary').toString('base64');
                                            const file = new File([blob], a.filenameOriginal);
                                            // this.attachments.push(file);
                                            this.attachments.push({attachment: file, url: URL.createObjectURL(file)});
                                            resolve();
                                        })
                                    })
                            }))


                        })
                        this.$set(this.email, "emailAttachments", [])


                        Promise.all(attachmentPromises).then(() => {
                            resolve();
                        })

                    } else {
                        resolve();
                    }


                })

            })
        },
        initEmail() {
            this.sender = {
                id: this.userInfo.contact_id,
                "@id": `/api/contacts/${this.userInfo.contact_id}`,
                nameWithCompany: this.userInfo.name
            };

            this.preventEditing = true;
            this.preventFiringBodyChanged = true;
            this.email = JSON.parse(JSON.stringify(this.emailTemplate))
            this.$nextTick(() => {
                this.preventEditing = false;
            })
            this.$set(this.email, "authorUser", `/api/users/${this.userInfo.id}`)
            this.attachments = [];

            this.loadAvailableDomains(this.userInfo.contact_id);
        },
        loadEmptyEmail() {
            return this.$axios.get("/api/emails/empty").then(response => {
                this.emailTemplate = response.data;
            });
        },
        loadEmptyEmailRecipient() {
            return this.$axios.get("/api/email_recipients/empty").then(response => {
                this.emailRecipientTemplate = response.data;
            });
        },
        loadEmptyContact() {
            return this.$axios.get("/api/contacts/empty").then(response => {
                this.contactTemplate = response.data;
            });
        },
        loadEmptyEmailAttachment() {
            return this.$axios.get("/api/email_attachments/empty").then(response => {
                this.emailAttachmentTemplate = response.data;
            });
        },
        removeAttachment(index) {
            this.email.emailAttachments.splice(index, 1)
        },
        // Removed not yet uploaded attachment
        spliceAttachment(index) {
            this.attachments.splice(index, 1);
            this.testSent = false;
        },
        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        },
        dragleave(event) {
            // Clean up
            this.highlightDropArea = false;
        },
        drop(event) {
            event.preventDefault();
            this.highlightDropArea = false;
            event.dataTransfer.files.forEach((file) => {
                this.attachments.push({attachment: file, url: URL.createObjectURL(file)});
            })
            this.testSent = false;
        },
        setAttachmentData(files) {
            files.forEach((file) => {
                this.attachments.push({attachment: file, url: URL.createObjectURL(file)});
            })
            this.testSent = false;
        },
        submitForm() {

            if (this.simulatingSubmit) {
                this.simulatingSubmit = false;
                return
            }

            // TODO: send also via outlook plugin

            this.doSubmitForm()

        },

        async checkValidation(submission) {

            // May or may not be successful
            this.hasErrors = await submission.hasValidationErrors();

            if (this.hasErrors) {
                window.scrollTo(0, 0);
            }

            if (this.callbackAfterValidation) {
                this.callbackAfterValidation()
                this.callbackAfterValidation = null;
            }

        },

        onBodyEditorInput() {
            if (this.preventFiringBodyChanged && !this.bodyKeyboardInput) {
                this.preventEditing = true;
                this.$nextTick(() => {
                    this.preventFiringBodyChanged = false;
                    this.preventEditing = false;
                })
            }

            // hack/work around to check if email body was just initialized, not needed anymore with ckeditor
            // if (this.email.id && this.preventFiringBodyChanged && data.loaded) {
            //     this.preventEditing = true;
            // }
            //
            // this.$nextTick(() => {
            //     this.preventFiringBodyChanged = false;
            //     this.preventEditing = false;
            // })

        },

        deleteEmail() {
            if (this.email.id) {
                this.confirmDeleteText = `Do you really want to delete this ${this.email.isDraft && !this.email.isTest ? 'draft' : this.email.isTemplate ? 'template' : 'email'}?`
            } else {
                this.confirmDeleteText = "Abort email writing?"
            }

            this.openModal(this.$refs.confirmDeleteModal)
        },

        doDeleteEmail() {
            if (this.email.id) {
                this.$axios.delete(`/api/emails/${this.email.id}`).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Deleted",
                        message: `${this.email.isDraft && !this.email.isTest ? 'Draft' : this.email.isTemplate ? 'Template' : 'Email'} was deleted!`,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                        this.$router.push("/emails");
                    })
                })
            } else {
                this.$router.push("/emails");
            }
        },

        cancelDeleteEmail() {
            this.confirmDeleteText = null;
        },

        async doSubmitForm(args = {}) {
            this.callbackBeforeNextSubmission = null;

            if (this.callbackOnSubmission) {
                this.callbackOnSubmission();
                this.callbackOnSubmission = null
            }

            if (this.email.isTest) {
                this.testSending = true;
            } else if (!this.email.isTemplate && !this.email.isDraft) { // && !this.autoSaved) {
                this.sending = true;
            } else {
                this.saving = true;
            }

            let message = "";

            this.toValidation = false;
            this.ccValidation = false;
            this.bccValidation = false;

            const email = JSON.parse(JSON.stringify(this.email));

            if (args.name) {
                email.name = args.name;
            }

            if (email.testRecipientUser && (typeof email.testRecipientUser === 'object') && email.testRecipientUser.hasOwnProperty('@id')) {
                email.testRecipientUser = email.testRecipientUser["@id"];
            }
            email.sendingContact = this.sender["@id"];
            email.senderDomain = String(this.selectedDomain);
            email.isM365Sender = this.emailTransport === 'm365';
            email.draftUser = this.email.draftUser ? (this.email.draftUser["@id"] ? this.email.draftUser["@id"] : this.email.draftUser) : null;
            email.authorUser = this.email.authorUser ? (this.email.authorUser["@id"] ? this.email.authorUser["@id"] : this.email.authorUser) : null;


            if (email.isDraft || !email.id && !email.isTest) {
                message = "Email draft was saved!"
            } else if (email.id && (email.isGroupEmail || email.emailRecipients.filter(x => x.isTo).length <= this.userInfo.email_batch_limit)) {
                // Null for now, because has to be determined after email was sent
                message = null;
            } else if (email.id) {
                message = `Email was sent to ${this.userInfo.email_batch_limit} recipients, other were scheduled!`
            }

            // scheduled
            if (args.scheduledAt && args.scheduledAtTimezone) {
                email.scheduledAt = args.scheduledAt;
                email.scheduledAtTimezone = args.scheduledAtTimezone;
                message = "Email was scheduled to " + this.formatDateTime(email.scheduledAt, email.scheduledAtTimezone);
            }

            if (email.isTemplate) {
                message = "Email template was saved!"
            }

            if (email.isTest) {
                message = "Email was sent to your inbox as a test!"
            }


            // recipients
            const recipients = [];


            this.recipients.to.forEach((toContact) => {
                // simple email address, not present in recipients of current email object
                if (!toContact["@id"] && !email.toContactEmails.includes(toContact.nameWithCompany)) {


                    if (!this.isEmailAddress(toContact.nameWithCompany)) {
                        this.toValidation = true
                        return
                    }

                    // add new recipient
                    const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    // add new contact
                    recipient.contact = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    recipient.contact.email = toContact.nameWithCompany
                    recipient.isTo = true;

                    delete recipient.email

                    recipients.push(recipient);

                }
                // new recipient, not present in recipients of current email object
                else if (toContact["@id"] && !email.toContactIds.includes(toContact.id)) {
                    const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    recipient.contact = toContact["@id"];
                    recipient.isTo = true;

                    delete recipient.email

                    recipients.push(recipient);
                }
            })


            this.recipients.cc.forEach((ccContact) => {
                if (!ccContact["@id"] && !email.ccContactEmails.includes(ccContact.nameWithCompany)) {

                    if (!this.isEmailAddress(ccContact.nameWithCompany)) {
                        this.ccValidation = true
                        return
                    }
                    // add new recipient
                    const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    // add new contact
                    recipient.contact = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    recipient.contact.email = ccContact.nameWithCompany
                    recipient.isCc = true;

                    delete recipient.email

                    recipients.push(recipient);

                } else if (ccContact["@id"] && !email.ccContactIds.includes(ccContact.id)) {
                    const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    recipient.contact = ccContact["@id"];
                    recipient.isCc = true;

                    delete recipient.email

                    recipients.push(recipient);
                }
            })

            this.recipients.bcc.forEach((bccContact) => {

                if (!bccContact["@id"] && !email.bccContactEmails.includes(bccContact.nameWithCompany)) {

                    if (!this.isEmailAddress(bccContact.nameWithCompany)) {
                        this.bccValidation = true
                        return
                    }

                    // add new recipient
                    const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    // add new contact
                    recipient.contact = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    recipient.contact.email = bccContact.nameWithCompany
                    recipient.isBcc = true;

                    delete recipient.email

                    recipients.push(recipient);

                } else if (bccContact["@id"] && !email.bccContactIds.includes(bccContact.id)) {
                    const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                    recipient.contact = bccContact["@id"];
                    recipient.isBcc = true;

                    delete recipient.email

                    recipients.push(recipient);
                }

            })

            if (this.toValidation || this.ccValidation || this.bccValidation) {
                if (this.email.isTest) {
                    this.testSending = false;
                } else {
                    this.sending = false;
                    this.saving = false;
                }
                window.scrollTo(0, 0)
                return
            }


            email.emailRecipients.forEach((r) => {

                if (r["@id"]) {
                    if (args.saveAs) {

                        const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                        recipient.isTo = r.isTo;
                        recipient.isCc = r.isCc;
                        recipient.isBcc = r.isBcc;
                        recipient.contactList = r.contactList ? r.contactList["@id"] : null;
                        recipient.contact = r.contact["@id"];
                        delete recipient.email

                        recipients.push(recipient);
                    } else {
                        if (
                            r.isTo &&
                            !r.contactList &&
                            (
                                this.recipients.to.map(r => r["@id"]).includes(r.contact["@id"]) ||
                                this.recipients.to.map(r => r.nameWithCompany).includes(r.contact.email)
                            )
                        ) {
                            recipients.push(r["@id"])
                        }
                        if (r.isCc && this.recipients.cc.map(r => r["@id"]).includes(r.contact["@id"])) {
                            recipients.push(r["@id"])
                        }

                        if (r.isBcc && this.recipients.bcc.map(r => r["@id"]).includes(r.contact["@id"])) {
                            recipients.push(r["@id"])
                        }
                    }
                }
            })


            for (const group of this.recipients.groups) {
                const response = await this.$axios.get(`${group["@id"]}?groups[]=contact_list:contacts`)
                const contactIds = response.data.contactIds;

                contactIds.forEach((id) => {
                    const contactId = `api/contacts/${id}`;
                    const newObjectIncluded = recipients.find(r => r.contact === contactId);
                    if (!newObjectIncluded && !email.toContactIds.includes(id)) {

                        const recipient = JSON.parse(JSON.stringify(this.emailRecipientTemplate))
                        recipient.contactList = group["@id"];
                        recipient.contact = contactId;
                        recipient.isTo = true;

                        delete recipient.email

                        recipients.push(recipient);
                    } else if (!newObjectIncluded) {
                        const existing = email.emailRecipients.find(r => r.contactId === id);
                        if (existing && !recipients.includes(existing["@id"])) {
                            recipients.push(existing["@id"]);
                        }
                    }
                })
            }

            // sort it, new elements are at the end of the list, otherwise it wont work with api platform
            email.emailRecipients = recipients.sort((a, b) => {
                if (typeof a === "string" && typeof b !== "string") {
                    return -1
                }
                if (typeof a === "string" && typeof b !== "string") {
                    return 1
                }
                if (typeof a === "string" && typeof b !== "string") {
                    return -1
                }

                // also sort the existing recipient ids asc, otherwise it will break, too
                if (typeof a === "string" && typeof b === "string") {
                    const intA = parseInt(a.split("/").pop());
                    const intB = parseInt(b.split("/").pop());

                    if (intA < intB) {
                        return -1
                    }
                    if (intA > intB) {
                        return 1
                    }
                }
                return 0
            });


            // attachments

            const attachments = [];


            if (this.attachments.length) {
                let attachmentsAdded = 0;

                email.files = {};

                /*
                this.attachments.forEach(async (file) => {

                    const attachment = JSON.parse(JSON.stringify(this.emailAttachmentTemplate))
                    attachment.filenameOriginal = file.attachment.name;
                    delete attachment.email
                    attachments.push(attachment);

                    console.log("Starting...", file.attachment.name);
                    await new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.attachment)
                        reader.onload = () => {
                            email.files[`emailAttachments.${attachmentsAdded}.file`] = {
                                filenameOriginal: file.attachment.name,
                                extension: file.attachment.name.split('.').pop(),
                                data: reader.result
                            };
                            attachmentsAdded++;
                            console.log("Finished...", file.attachment.name);
                            resolve();
                        };
                    });
                });
                 */

                for (let i = 0; i < this.attachments.length; i++) {
                    const file = this.attachments[i];


                    const attachment = JSON.parse(JSON.stringify(this.emailAttachmentTemplate))
                    attachment.filenameOriginal = file.attachment.name;
                    delete attachment.email
                    attachments.push(attachment);

                    await new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.attachment)
                        reader.onload = () => {
                            email.files[`emailAttachments.${attachmentsAdded}.file`] = {
                                filenameOriginal: file.attachment.name,
                                extension: file.attachment.name.split('.').pop(),
                                data: reader.result
                            };
                            attachmentsAdded++;
                            resolve();
                        };
                    });
                }
            }

            email.emailAttachments.forEach((attachment) => {
                attachments.push(attachment["@id"])
            });

            email.emailAttachments = attachments;

            let promise;

            if (!email.id) {
                // Sending/saving email for the first time POST
                promise = this.$axios.post("/api/emails", email)
                    .then(response => {

                        if (message === null) {
                            message = this.createEmailSentMessage(email, response.data);
                        }

                        this.preventEditing = true;
                        this.preventFiringBodyChanged = response.data.body !== this.email.body;
                        this.email = response.data;
                        this.$nextTick(() => {
                            this.preventEditing = false;
                        })

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Success",
                            message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);

                            if (!this.email.isTest && this.email.isDraft) {// && !this.autoSaved) {
                                this.$router.push("/emails/start")
                            } else if (this.email.isTemplate) { // && !this.autoSaved) {
                                this.$router.push("/emails/start")
                            } else if (this.$route.path !== `/emails/${response.data.id}`) {
                                this.setStoredEmail(JSON.parse(JSON.stringify(response.data)));
                                this.$router.replace(`/emails/${response.data.id}`)
                            }
                        })


                    })
            } else {
                // Sending/saving an email for the second time PATCH
                promise = this.$axios.patch(`/api/emails/${email.id}`, email, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then(response => {

                    if (message === null) {
                        message = this.createEmailSentMessage(email, response.data);
                    }

                    this.preventEditing = true;
                    this.preventFiringBodyChanged = response.data.body !== this.email.body;
                    this.email = response.data;
                    this.$nextTick(() => {
                        this.preventEditing = false;
                    })

                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);

                        if (!this.email.isTest) {
                            if (this.email.isSending) {
                                this.$router.push("/emails/campaigns")
                            } else if ((this.email.isTemplate || this.email.isDraft)) {// && !this.autoSaved) {
                                this.$router.push("/emails/start")
                            } else if (this.email.scheduledAt) {
                                this.$router.push("/emails/campaigns")
                            } else if (!this.email.isDraft && !this.email.isTemplate) {
                                this.$router.push("/emails/campaigns")
                            }
                        }

                    })

                })
            }

            promise
                .then(() => {
                    this.attachments = [];

                    if (this.email.isTest) {
                        this.testSent = true;
                    }
                })
                .finally(() => {
                    if (this.email.isTest) {
                        this.testSending = false;
                        this.preventEditing = true;
                        this.$set(this.email, "isTest", false)
                        this.$nextTick(() => {
                            this.preventEditing = false;
                        })

                    } else {
                        this.sending = false;
                        this.saving = false;
                    }
                    // this.autoSaved = false;
                })
        },
        getFilesizes(id) {
            this.$axios.get('/api/emails/attachment/size/' + id)
                .then((res) => {
                    this.sizes = res.data
                });
        },
        bytesToSize(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },

        triggerSubmit() {
            this.$formulate.submit("email-form");
        },

        /**
         * Used to run validation but not do an actual submit.
         */
        simulateSubmit() {
            this.$formulate.resetValidation("email-form");

            this.simulatingSubmit = true;

            this.triggerSubmit();
        },

        validateBeforeSending() {
            const error = this.getBeforeSendingError();
            if (!error) {
                return true;
            }

            this.sendDropdownUpdated = moment().valueOf();

            const toastId = this.generateUUID();
            this.addToast({
                type: "warning",
                title: "Cannot send",
                message: error,
                id: toastId,
            });
            this.$nextTick(() => {
                this.toggleToast(toastId);
            });
        },
        getBeforeSendingError() {
            // if (!this.email.scheduledAt) {
            if (!this.testSent && this.email.testRequired) {
                return 'A test email must be sent first';
            }
            if (this.email.sentAt) {
                return 'This email was already sent';
            }
            // }
            if (this.userInfo.disable_sending_as && this.sender.id !== this.userInfo.contact_id) {
                return `You are not permitted to send as ${this.sender.nameWithCompany}`;
            }
            return null;
        },
        onCancelSaveModal() {
            if (this.callbackBeforeNextSubmission) {
                this.callbackBeforeNextSubmission();
                this.callbackBeforeNextSubmission = null;
                this.$formulate.resetValidation("email-form");

            }
        },
        createEmailSentMessage(emailBeforeSending, emailAfterSending) {
            const numberExpectedRecipients = emailBeforeSending.emailRecipients.length;
            const numberActualRecipients = emailAfterSending.emailRecipients.length;
            if (numberExpectedRecipients === numberActualRecipients) {
                return 'The email was sent to all recipients!';
            }

            const numberMissedRecipients = numberExpectedRecipients - numberActualRecipients;

            const notReceivedReasons = [];
            if (!emailAfterSending.includeTemporaryContacts) {
                notReceivedReasons.push('temporary')
            }
            if (!emailAfterSending.includeInactiveContacts) {
                notReceivedReasons.push('inactive');
            }
            notReceivedReasons.push('excluded from emails');
            notReceivedReasons.push(
                this.pluralize(numberMissedRecipients, 'the contact has no email address', 'the contacts have no email address', null, false)
            );

            return `The email sent to ${numberActualRecipients} of ${this.pluralize(numberExpectedRecipients, 'recipient')}! It was not sent to ` +
                this.pluralize(numberMissedRecipients, 'recipient') +
                ', because ' +
                this.pluralize(numberMissedRecipients, 'the contact is ', 'the contacts are ', null, false) +
                this.formatArray(notReceivedReasons, 'or') + '.';
        },
        openRevisionModal() {
            this.closeRevisionModal();
            this.$refs.emailRevisionModal.resetWorkingCopy();
            this.revisionModal = this.openModal(this.$refs.emailRevisionModal);
        },
        closeRevisionModal() {
            if(this.revisionModal) {
                this.closeModal(this.revisionModal);
                this.revisionModal = null;
            }
            this.$refs.emailRevisionModal.generating = false;
        },
        updateEmail(subject, body) {
            this.email.subject = subject;
            this.email.body = body;
        },
    },

}
