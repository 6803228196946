import { render, staticRenderFns } from "./event-bulk-operation-modal.vue?vue&type=template&id=0cb2eff3&scoped=true"
import script from "./event-bulk-operation-modal.vue?vue&type=script&lang=js"
export * from "./event-bulk-operation-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb2eff3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2108204121/src/web/components/loading-spinner.vue').default,HintText: require('/codebuild/output/src2108204121/src/web/components/hint-text.vue').default,SaveButton: require('/codebuild/output/src2108204121/src/web/components/save-button.vue').default})
